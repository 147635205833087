const Configuration = {
  REACT_APP_BRANDING_LOGO_UPLOAD_ENDPOINT:
    process.env.REACT_APP_BRANDING_LOGO_UPLOAD_ENDPOINT || "",
  REACT_APP_BRANDING_LOGO_DELETE_ENDPOINT:
    process.env.REACT_APP_BRANDING_LOGO_DELETE_ENDPOINT || "",
  REACT_APP_BRAND_LIST_ENDPOINT:
    process.env.REACT_APP_BRAND_LIST_ENDPOINT || "",
  REACT_APP_CLIENT_LIST_ENDPOINT:
    process.env.REACT_APP_CLIENT_LIST_ENDPOINT || "",
  REACT_APP_BRANDING_LOGO_ENDPOINT:
    process.env.REACT_APP_BRANDING_LOGO_ENDPOINT || "",
  REACT_APP_SAS_TOKEN_PROVIDER_ENDPOINT:
    process.env.REACT_APP_SAS_TOKEN_PROVIDER_ENDPOINT || "",
  REACT_APP_CLIENT_LOGO_POLICY: process.env.REACT_APP_CLIENT_LOGO_POLICY || "",
  REACT_APP_HGEM_API_BASIC_AUTH_HEADER:
    process.env.REACT_APP_HGEM_API_BASIC_AUTH_HEADER || "",
  REACT_APP_AZURE_FUNCTION_KEY: process.env.REACT_APP_AZURE_FUNCTION_KEY || "",
  REACT_APP_CLIENT_ID: process.env.REACT_APP_CLIENT_ID || "",
  REACT_APP_AUTHORITY: process.env.REACT_APP_AUTHORITY || "",
  REACT_APP_REDIRECT_URI: process.env.REACT_APP_REDIRECT_URI || "",
  REACT_APP_B2C_GET_DINER_ENDPOINT:
    process.env.REACT_APP_B2C_GET_DINER_ENDPOINT || "",
  REACT_APP_B2C_UPDATE_DINER_ENDPOINT:
    process.env.REACT_APP_B2C_UPDATE_DINER_ENDPOINT || "",
  REACT_APP_B2C_FUNCTION_KEY: process.env.REACT_APP_B2C_FUNCTION_KEY || "",
};

export default Configuration;
