import { action, observable } from "mobx";
import * as Msal from "msal";
import { UserAgentApplication } from "msal";
import Configuration from "../Configuration";

const clientId = Configuration.REACT_APP_CLIENT_ID;
const authority = Configuration.REACT_APP_AUTHORITY;

class AuthStore {
  @observable public isLoggedIn: boolean = false;
  @observable public accessToken: string = "";

  private msalClient: UserAgentApplication;

  constructor() {
    this.msalClient = new Msal.UserAgentApplication(
      clientId,
      authority,
      this.recievedTokenCallback,
      {
        redirectUri: Configuration.REACT_APP_REDIRECT_URI,
        cacheLocation: "localStorage",
      },
    );
  }

  public getAccessToken = async () => {
    try {
      const result = await this.msalClient.acquireTokenSilent([
        "user.read",
        "directory.read.all",
      ]);

      this.setAuthenticatedProperties(result);
    } catch (error) {
      this.resetAll();
      if (error === "user_login_error|User login is required") {
        this.login();
      }
    }
  }

  public logout() {
    this.msalClient.logout();
    this.resetAll();
  }

  public getUserName(): string {
    const user = this.msalClient.getUser();
    return user ? user.name : "";
  }

  private login() {
    this.msalClient.loginRedirect(["user.read", "directory.read.all"]);
  }

  private recievedTokenCallback = (
    errorDesc: string,
    token: string,
    error: string,
    tokenType: string,
    userState: string,
  ) => {
    if (!error) {
      this.getAccessToken();
    }
  }

  @action
  private resetAll() {
    this.isLoggedIn = false;
    this.accessToken = "";
  }

  @action
  private setAuthenticatedProperties(accessToken: string) {
    this.isLoggedIn = true;
    this.accessToken = accessToken;
  }
}

export default AuthStore;
