import React from "react";
import { Col, Grid, Row } from "react-flexbox-grid";

class Callback extends React.Component {
  public render() {
    return (
      <Grid fluid>
        <Row center="xs" middle="xs">
          <Col xs={12}>
            <label>Authenticating...</label>
          </Col>
        </Row>
      </Grid>
    );
  }
}

export default Callback;
