import { observable, runInAction } from "mobx";
import B2CApi from "../../dinerDetails/services/apis/B2CApi";

export default class DinerDetailsStore {
  @observable public error: string = "";
  @observable public isLoadingGetDiner: boolean = false;
  @observable public searchEmail: string = "";
  @observable public b2cDinerId: number = 0;
  @observable public exists: boolean = false;
  @observable public b2cUpdateDinerId: number = 0;
  @observable public isLoadingDinerUpdate: boolean = false;

  private B2CApi: B2CApi;

  constructor() {
    this.B2CApi = new B2CApi();
  }

  public setDinerUpdateId = async (dinerId: number) => {
    runInAction(() => {
      this.b2cUpdateDinerId = dinerId;
    });
  }

  public setDinerId = async (dinerId: number) => {
    runInAction(() => {
      this.b2cDinerId = dinerId;
    });
  }

  public setEmail(email: string) {
    runInAction(() => {
      this.searchEmail = email;
    });
  }

  public getDinerId = async () => {
    runInAction(() => {
      this.isLoadingGetDiner = true;
      this.error = "";
      this.exists = false;
    });

    try {
      const dinerId = await this.B2CApi.getDinerIdByEmail(this.searchEmail);
      runInAction(() => {
        this.b2cDinerId = dinerId;
        this.exists = true;
      });
    } catch (error) {
      runInAction(() => {
        this.error = JSON.stringify(error);
        this.b2cDinerId = 0;
      });
    } finally {
      runInAction(() => {
        this.isLoadingGetDiner = false;
      });
    }
  }

  public updateDinerId = async () => {
    runInAction(() => {
      this.error = "";
      this.isLoadingDinerUpdate = true;
    });
    try {
      await this.B2CApi.updateDinerByEmail(
        this.searchEmail,
        this.b2cUpdateDinerId,
      );
    } catch (error) {
      runInAction(() => {
        this.isLoadingDinerUpdate = false;
        this.error = JSON.stringify(error);
      });
    } finally {
      runInAction(() => {
        this.isLoadingDinerUpdate = false;
      });
    }
  }
}
