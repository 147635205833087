import { inject } from "mobx-react";
import React from "react";
import AuthStore from "./stores/AuthStore";

interface IProps {
  authStore: AuthStore;
}

@inject("authStore")
class Logout extends React.Component<IProps> {
  public componentDidMount() {
    this.props.authStore.logout();
  }
  public render() {
    return <label>Logging out...</label>;
  }
}

export default Logout;
