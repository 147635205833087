import axios from "axios";
import { observable, runInAction } from "mobx";
import Configuration from "../Configuration";
import { IClient } from "./ClientStore";

export interface IBrand {
  key: number;
  value: string;
}

class BrandStore {
  @observable public error: string = "";
  @observable public selectedClient?: IClient;
  @observable public brands: IBrand[] = [];
  @observable public isLoadingBrands: boolean = false;

  public getBrands = async (client: IClient) => {
    runInAction(() => {
      this.selectedClient = client;
      this.isLoadingBrands = true;
      this.error = "";
    });

    try {
      const result = await axios.get<IBrand[]>(
        Configuration.REACT_APP_BRAND_LIST_ENDPOINT + client.key,
        {
          headers: {
            Authorization: Configuration.REACT_APP_HGEM_API_BASIC_AUTH_HEADER,
          },
        },
      );

      runInAction(() => {
        this.brands = result.data;
      });
    } catch (error) {
      runInAction(() => {
        this.error = "An error occurred loading brands";
      });
    } finally {
      runInAction(() => {
        this.isLoadingBrands = false;
      });
    }
  }
}

export default BrandStore;
