import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import { inject, observer } from "mobx-react";
import React, { Component, Fragment } from "react";
import BrandingLogoUpload from "./branding/client-logos/ClientLogoUploadPage";
import DinerDetailsPage from "./dinerDetails/DinerDetailsPage";
import GraphStore from "./stores/GraphStore";

interface IProps {
  graphStore: GraphStore;
}

interface IState {
  value: number;
  isDeveloper: boolean;
}
@inject("graphStore")
@observer
export default class TabbedPage extends Component<IProps, IState> {
  constructor(props: any) {
    super(props);

    this.state = {
      value: 0,
      isDeveloper: false,
    };
  }

  public async componentDidMount() {
    await this.props.graphStore.getAllGroupsIBelongTo();
    this.setState({
      isDeveloper:
        this.props.graphStore.groups.filter((x) => x.displayName === "Developers")
          .length > 0,
    });
  }

  public handleChange = (event: any, value: any) => {
    this.setState({ value });
  }

  public render() {
    return (
      <Fragment>
        <Tabs value={this.state.value} onChange={this.handleChange}>
          <Tab label="Logo-Upload" />
          <Tab label="Diner-Details" disabled={!this.state.isDeveloper} />
        </Tabs>
        {this.state.value === 0 && <div> {<BrandingLogoUpload />} </div>}
        {this.state.value === 1 && <div> {<DinerDetailsPage />} </div>}
      </Fragment>
    );
  }
}
