import React, { CSSProperties, MouseEvent } from "react";
import { IconContext } from "react-icons";
import { MdCloudUpload, MdDeleteForever } from "react-icons/md";
import "./Button.scss";

interface IProps {
  text: string;
  type?: string;
  disabled?: boolean;
  style?: CSSProperties;
  icon?: JSX.Element;
  className?: string;
  onClick?(e: MouseEvent<HTMLElement>): void;
}

interface IDefaultProps {
  type?: string;
  disabled?: boolean;
  style?: CSSProperties;
  icon?: JSX.Element;
  className?: string;
  onClick?(e: MouseEvent<HTMLElement>): void;
}

class Button extends React.Component<IProps> {
  public static defaultProps: IDefaultProps = {
    type: "button",
    className: "",
    disabled: false,
  };

  public render() {
    return (
      <button
        className={`button ${this.props.className}`}
        type={this.props.type}
        disabled={this.props.disabled}
        onClick={this.props.onClick}
        style={this.props.style}
      >
        {this.props.text}
        {this.props.icon !== undefined && (
          <IconContext.Provider value={{ className: "icon" }}>
            {this.props.icon}
          </IconContext.Provider>
        )}
      </button>
    );
  }
}

export default Button;
