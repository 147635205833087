import { inject, observer } from "mobx-react";
import React from "react";
import { Col, Row } from "react-flexbox-grid";
import { Link } from "react-router-dom";
import AuthStore from "../stores/AuthStore";
import "./NavigationBar.scss";

interface IProps {
  authStore: AuthStore;
}

@inject("authStore")
@observer
class NavigationBar extends React.Component<IProps> {
  constructor(props: IProps) {
    super(props);
  }
  public render() {
    return (
      <Row className={"navigationBar fixed-top"}>
        <Col xs={12}>
          <Row>
            <Col xs={6}>
              <Link to={"/"} className={"logo"} />
            </Col>
            <Col xs={6}>
              <Row className="text-end" end="xs">
                <Col xs={12}>
                  {this.props.authStore.isLoggedIn && (
                    <label className={"logout"}>
                      {this.props.authStore.getUserName()}{" "}
                      <Link to={`/logout`}>Logout</Link>
                    </label>
                  )}
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
    );
  }
}

export default NavigationBar;
