import { observable, runInAction } from "mobx";
import AzureGraphApi from "../dinerDetails/services/apis/AzureGraphApi";
import AuthStore from "./AuthStore";

interface IGraphGroup {
  displayName: string;
}

class GraphStore {
  @observable public isLoading: boolean = false;
  @observable public error: string = "";
  @observable public groups: IGraphGroup[] = [];

  private AzureGraphApi: AzureGraphApi;
  private AuthStore: AuthStore;

  constructor(authStore: AuthStore) {
    this.AzureGraphApi = new AzureGraphApi();
    this.AuthStore = authStore;
  }

  public async getAllGroupsIBelongTo(): Promise<void> {
    runInAction(() => {
      this.isLoading = true;
    });

    await this.AuthStore.getAccessToken();
    try {
      const groupNames = await this.AzureGraphApi.allGroupsIBelongTo(
        this.AuthStore.accessToken,
      );

      runInAction(() => {
        this.groups = groupNames;
      });
    } catch (error) {
      runInAction(() => {
        this.error = error.message;
      });
    } finally {
      runInAction(() => {
        this.isLoading = false;
      });
    }
  }
}

export default GraphStore;
