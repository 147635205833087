import axios, { AxiosResponse } from "axios";
import Configuration from "../../Configuration";

export interface IClientLogoService {
  upload(
    file: File,
    clientId: number,
    brandId?: number,
  ): Promise<AxiosResponse<any>>;

  delete(clientId: number, brandId?: number): Promise<AxiosResponse<any>>;
}

export class ClientLogoService implements IClientLogoService {
  public async delete(
    clientId: number,
    brandId?: number | undefined,
  ): Promise<AxiosResponse<any>> {
    const url = brandId ? clientId + "/" + brandId : clientId;

    return await axios.delete(
      Configuration.REACT_APP_BRANDING_LOGO_DELETE_ENDPOINT + url,
      {
        headers: {
          "x-functions-key": Configuration.REACT_APP_AZURE_FUNCTION_KEY,
        },
      },
    );
  }

  public async upload(
    file: File,
    clientId: number,
    brandId?: number,
  ): Promise<AxiosResponse<any>> {
    const formData = new FormData();
    formData.append("file", file);

    const url = brandId ? clientId + "/" + brandId : clientId;

    return await axios.post(
      Configuration.REACT_APP_BRANDING_LOGO_UPLOAD_ENDPOINT + url,
      formData,
      {
        headers: {
          "x-functions-key": Configuration.REACT_APP_AZURE_FUNCTION_KEY,
        },
      },
    );
  }
}
