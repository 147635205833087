import React, { Component } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import AuthenticatedRoute from "./components/AuthenticatedRoute";
import Callback from "./Callback";
import Logout from "./Logout";
import { Provider } from "mobx-react";
import stores from "./stores/Stores";
import { Grid, Col, Row } from "react-flexbox-grid";
import NavigationBar from "./components/NavigationBar";
import TabbedPage from "../src/TabbedPage";
import "./App.css";

class App extends Component {
  render() {
    return (
      <Provider {...stores}>
        <BrowserRouter>
          <div>
            <NavigationBar />
            <Grid className={"page"} fluid>
              <Row>
                <Col xs={12}>
                  <Switch>
                    <AuthenticatedRoute
                      exact
                      path="/"
                      component={TabbedPage}
                    />
                    <Route path="/logout" component={Logout} />
                    <Route path="/callback" component={Callback} />         
                    <Route component={() => <p>PAGE NOT FOUND!</p>} />     
                  </Switch>
                </Col>
              </Row>
            </Grid>
          </div>
        </BrowserRouter>
      </Provider>
    );
  }
}

export default App;
