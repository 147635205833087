import axios, { AxiosResponse } from 'axios';
import { observable, runInAction } from 'mobx';
import Configuration from '../Configuration';

class SasTokenStore {
	@observable public inProgress: boolean = false;
	@observable public sasToken: string = '';
	@observable public error: string = '';

	public getSasToken = async (policy: string): Promise<void> => {
		runInAction(() => {
			this.inProgress = true;
			this.error = '';
		});

		try {
			const sasTokenUrl = await this.getSasTokenUrl(policy);

			const characterSeparatedStrings: RegExpExecArray | null = /\?[^\?]*$/.exec(
				sasTokenUrl
			);

			if (!characterSeparatedStrings) {
				throw new Error('An error occurred parsing sas token');
			}

			const queryString = characterSeparatedStrings.find((query) => !!query);

			if (!queryString) {
				throw new Error('An error occurred parsing sas token');
			}

			runInAction(() => {
				this.sasToken = queryString;
			});
		} catch (error) {
			runInAction(() => {
				this.error = (error as Error).message;
			});
		} finally {
			runInAction(() => {
				this.inProgress = false;
			});
		}
	};

	private getSasTokenUrl = async (policy: string): Promise<string> => {
		try {
			const response = await axios.get<string>(
				Configuration.REACT_APP_SAS_TOKEN_PROVIDER_ENDPOINT + policy,
				{
					headers: {
						'x-functions-key': Configuration.REACT_APP_AZURE_FUNCTION_KEY,
					},
				}
			);

			return response.data;
		} catch (error) {
			throw new Error('An error occurred getting sas token');
		}
	};
}

export default SasTokenStore;
