import React from "react";
import Dropzone from "react-dropzone";
import { Col, Grid, Row } from "react-flexbox-grid";
import { MdCloudUpload, MdDeleteForever } from "react-icons/md";
import Img from "react-image";
import { Alert, AlertType } from "../../components/Alert";
import Button from "../../components/Button";
import "./ClientLogoUploadCard.scss";

interface IProps {
  item: any;
  imageSrc: string;
  dropzoneText: string;
  onUpload?(file: File): Promise<boolean>;
  onDelete?(imageSrc: string): Promise<boolean>;
}

interface IState {
  preview: string;
  addedFile?: File;
  errorMessage: string;
  isUploading: boolean;
  isDeleting: boolean;
  showPreview: boolean;
  random: number;
}

class ClientLogoUploadCard extends React.Component<IProps, IState> {
  public static defaultProps: IProps = {
    dropzoneText: "Drag & Drop Files",
    imageSrc: "",
    item: {},
  };

  public state: IState = {
    preview: "",
    errorMessage: "",
    isUploading: false,
    isDeleting: false,
    showPreview: true,
    random: Math.random(),
  };

  constructor(props: IProps) {
    super(props);
  }

  public render(): JSX.Element {
    return (
      <div className="client-upload-card">
        <Grid className="brand-card-container" fluid>
          <Row center="xs">
            <Col xs={12}>
              <div className="image-container">
                <Img
                  className="previewer"
                  width="auto"
                  height="150px"
                  src={this.getImageSrc()}
                  loader={
                    <Col xs={12} className="loading-container">
                      <label>Loading...</label>
                    </Col>
                  }
                  unloader={
                    <Dropzone
                      className="dropzone"
                      accept="image/png, image/jpeg"
                      onDrop={this.onDrop}
                      multiple={false}
                    >
                      {this.props.dropzoneText && (
                        <p className="dropzone-text">
                          {this.props.dropzoneText}
                        </p>
                      )}
                    </Dropzone>
                  }
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <label>{this.props.item.value}</label>
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              {this.state.addedFile && (
                <div>
                  <Button
                    disabled={this.state.isUploading}
                    text={this.state.isUploading ? "Uploading..." : "Upload"}
                    onClick={this.uploadClicked.bind(this)}
                    className="upload-button"
                    icon={<MdCloudUpload />}
                  />
                  <Button
                    text="Cancel"
                    onClick={this.cancelClicked.bind(this)}
                    className="cancel-button"
                  />
                </div>
              )}
              {!this.state.addedFile && (
                <Button
                  text={this.state.isDeleting ? "Deleting..." : "Delete"}
                  onClick={this.deleteClicked.bind(this)}
                  className="upload-button"
                  icon={<MdDeleteForever />}
                  disabled={this.state.isDeleting}
                />
              )}
            </Col>
            <Col xs={12}>
              {this.state.errorMessage && (
                <Alert
                  onClick={() => this.setState({ errorMessage: "" })}
                  type={AlertType.error}
                  text={this.state.errorMessage}
                />
              )}
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }

  public componentWillReceiveProps(): void {
    URL.revokeObjectURL(this.state.preview);
    this.setState({
      preview: "",
      errorMessage: "",
      isUploading: false,
      addedFile: undefined,
    });
  }

  public componentWillUnmount(): void {
    URL.revokeObjectURL(this.state.preview);
    this.setState({
      addedFile: undefined,
    });
  }

  private getImageSrc(): string[] {
    return this.state.showPreview
      ? [
          this.state.preview,
          this.props.imageSrc + "&random=" + this.state.random,
        ]
      : [];
  }

  private async deleteClicked() {
    this.setState({
      isDeleting: true,
      errorMessage: "",
    });

    if (this.props.onDelete === undefined) {
      this.setState({
        isDeleting: false,
      });
      return;
    }

    try {
      const deleted = await this.props.onDelete(this.props.imageSrc);

      if (deleted) {
        this.setState({
          addedFile: undefined,
          preview: "",
          isDeleting: false,
          errorMessage: "",
          showPreview: false,
          random: Math.random(),
        });
      } else {
        this.setState({
          isDeleting: false,
          showPreview: true,
          errorMessage: "ERROR: could not delete image",
        });
      }
    } catch (error) {
      this.setState({
        isDeleting: false,
        showPreview: true,
        errorMessage: "ERROR: could not delete image",
      });
    }
  }

  private async uploadClicked(): Promise<void> {
    this.setState({
      isUploading: true,
    });

    if (this.state.addedFile === undefined) {
      return;
    }

    if (this.props.onUpload === undefined) {
      this.setState({
        isUploading: false,
      });
      return;
    }

    try {
      const uploaded = await this.props.onUpload(this.state.addedFile);

      if (uploaded) {
        this.setState({
          addedFile: undefined,
          isUploading: false,
          errorMessage: "",
          showPreview: true,
        });
      } else {
        throw new Error();
      }
    } catch (error) {
      this.setState({
        addedFile: undefined,
        isUploading: false,
        errorMessage: "ERROR: could not upload image",
        preview: "",
        showPreview: false,
      });
    }
  }

  private cancelClicked(): void {
    this.setState({
      showPreview: false,
      preview: "",
      errorMessage: "",
      isUploading: false,
      addedFile: undefined,
    });
  }

  private onDrop = (files: File[]): void => {
    if (!files[0]) {
      return;
    }

    this.setState({
      preview: URL.createObjectURL(files[0]),
      addedFile: files[0],
      errorMessage: "",
      showPreview: true,
    });
  }
}

export default ClientLogoUploadCard;
