import AuthStore from "./AuthStore";
import BrandStore from "./BrandStore";
import ClientStore from "./ClientStore";
import GraphStore from "./GraphStore";
import SasTokenStore from "./SasTokenStore";
import DinerDetailsStore from "./view/DinerDetailsStore";

const clientStore = new ClientStore();
const brandStore = new BrandStore();
const sasTokenStore = new SasTokenStore();
const authStore = new AuthStore();
const graphStore = new GraphStore(authStore);
const dinerDetailsStore = new DinerDetailsStore();

const stores = {
  clientStore,
  brandStore,
  sasTokenStore,
  authStore,
  graphStore,
  dinerDetailsStore,
};

export default stores;
