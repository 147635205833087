import axios from "axios";

interface IGraphGroup {
  displayName: string;
}

export default class AzureGraphApi {
  public async allGroupsIBelongTo(token: string): Promise<IGraphGroup[]> {
    const result = await axios.get<any>(
      "https://graph.microsoft.com/v1.0/me/memberOf",
      {
        headers: {
          Accept: "application/json",
          authorization: `Bearer ${token}`,
        },
      },
    );

    return result.data.value;
  }
}
