import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import TextField from "@material-ui/core/TextField";
import { inject, observer } from "mobx-react";
import React, { Component, Fragment } from "react";
import DinerDetailsStore from "../stores/view/DinerDetailsStore";

interface IProps {
  dinerDetailsStore?: DinerDetailsStore;
}

@inject("dinerDetailsStore")
@observer
export default class DinerDetailsPage extends Component<IProps> {
  constructor(props: any) {
    super(props);
  }

  public render() {
    const store = this.props.dinerDetailsStore!;

    return (
      <Fragment>
        <div>
          <h4>Diner B2C Details</h4>
          <form
            onSubmit={async (e) => {
              e.preventDefault();
              await store.getDinerId();

              store.setDinerUpdateId(store.b2cDinerId);
            }}
          >
            <TextField
              margin="normal"
              placeholder={"email..."}
              value={store.searchEmail}
              onChange={(event) => {
                store.setEmail(event.target.value);
              }}
            />
            <Button
              type={"submit"}
              variant="contained"
              color="primary"
              onClick={async (e) => {
                await store.getDinerId();
                e.preventDefault();
              }}
            >
              Search
            </Button>
          </form>
        </div>
        <div>{store.isLoadingGetDiner && <CircularProgress />}</div>
        <div>
          <p>Diner Id : {store.b2cDinerId}</p>
          {store.exists && !store.isLoadingGetDiner && (
            <Fragment>
              <div>
                <TextField
                  placeholder={"DinerUID"}
                  value={store.b2cUpdateDinerId}
                  onChange={(event) => {
                    const fieldValue = event.target.value;
                    store.setDinerUpdateId(parseInt(fieldValue, 10) || 0);
                  }}
                />
                <Button
                  color="secondary"
                  onClick={async (e) => {
                    await store.updateDinerId();

                    store.setDinerId(store.b2cUpdateDinerId);
                    e.preventDefault();
                  }}
                >
                  Submit Update
                </Button>
              </div>
              <div>{store.isLoadingDinerUpdate && <CircularProgress />}</div>
            </Fragment>
          )}
          <p>{store.error}</p>
        </div>
      </Fragment>
    );
  }
}
