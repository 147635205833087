import { inject, observer } from "mobx-react";
import React from "react";
import { Col, Grid, Row } from "react-flexbox-grid";
import Select from "react-select";
import { ValueType } from "react-select/lib/types";
import { Alert, AlertType } from "../../components/Alert";
import Configuration from "../../Configuration";
import BrandStore, { IBrand } from "../../stores/BrandStore";
import ClientStore, { IClient } from "../../stores/ClientStore";
import SasTokenStore from "../../stores/SasTokenStore";
import { ClientLogoService, IClientLogoService } from "./ClientLogoService";
import ClientLogoUploadCard from "./ClientLogoUploadCard";
import "./ClientLogoUploadPage.scss";

interface IProps {
  clientStore?: ClientStore;
  brandStore?: BrandStore;
  sasTokenStore?: SasTokenStore;
}

const contactDeveloperTeamMessage =
  "if the problem persists please contact a member of the development team.";

@inject("clientStore", "brandStore", "sasTokenStore")
@observer
class BrandingLogoUpload extends React.Component<IProps, {}> {
  private clientLogoService: IClientLogoService = new ClientLogoService();

  constructor(props: IProps) {
    super(props);
    document.title = "Branding - Client logos";
  }

  public handleChange = async (value: ValueType<IClient>) => {
    if (!value) {
      return;
    }

    const client = value as IClient;

    await this.props.brandStore!.getBrands(client);
  }

  public async componentDidMount() {
    await this.props.sasTokenStore!.getSasToken(
      Configuration.REACT_APP_CLIENT_LOGO_POLICY,
    );
    await this.props.clientStore!.getClients();
  }

  public render() {
    const renderPageLoading = () => {
      return (
        !this.props.clientStore!.clients.length &&
        !this.props.sasTokenStore!.error &&
        !this.props.clientStore!.error && (
          <Row>
            <Col xs={12}>
              <label>Loading...</label>
            </Col>
          </Row>
        )
      );
    };

    const renderPageLoadingErrors = () => {
      return (
        <Row>
          {this.props.sasTokenStore!.error && (
            <Col xs={12}>
              <Alert
                type={AlertType.error}
                text={`${
                  this.props.sasTokenStore!.error
                }, ${contactDeveloperTeamMessage}`}
              />
            </Col>
          )}
          {this.props.clientStore!.error && (
            <Col xs={12}>
              <Alert
                type={AlertType.error}
                text={`${
                  this.props.clientStore!.error
                }, ${contactDeveloperTeamMessage}`}
              />
            </Col>
          )}
        </Row>
      );
    };

    return (
      <Grid fluid className="client-logo-upload-page">
        {renderPageLoading()}
        {renderPageLoadingErrors()}
        {this.props.clientStore!.clients.length > 0 &&
          !this.props.sasTokenStore!.error &&
          !this.props.clientStore!.error && (
            <Grid fluid>
              <Row>
                <Col xs={12}>
                  <h1>Branding - Client logos</h1>
                </Col>
                <Col xs={12}>
                  <label className="label-text">Clients</label>
                </Col>
                <Col xs={12}>
                  <div className="select-container">
                    <Select
                      autoFocus={true}
                      placeholder="Select or type client name or id..."
                      onChange={this.handleChange}
                      options={this.props.clientStore!.clients}
                      getOptionValue={(option: IClient) => option.value}
                      getOptionLabel={(option: IClient) =>
                        option.value + " - " + option.key
                      }
                    />
                  </div>
                </Col>
              </Row>
              {this.props.brandStore!.selectedClient && (
                <Row>
                  <Col xs={12}>
                    <label className="label-text">Client logo</label>
                  </Col>
                  <Col xs={12}>
                    <div style={{ marginRight: 20, marginBottom: 20 }}>
                      <ClientLogoUploadCard
                        key={this.props.brandStore!.selectedClient!.key}
                        onUpload={(file) => this.onUpload(file)}
                        onDelete={() =>
                          this.onDelete(this.props.brandStore!.selectedClient)
                        }
                        dropzoneText={
                          "No image is set for client " +
                          this.props.brandStore!.selectedClient!.value +
                          ". Click or Drag & drop an image"
                        }
                        item={this.props.brandStore!.selectedClient}
                        imageSrc={
                          Configuration.REACT_APP_BRANDING_LOGO_ENDPOINT +
                          this.props.brandStore!.selectedClient!.key +
                          "/Logo" +
                          this.props.sasTokenStore!.sasToken
                        }
                      />
                    </div>
                  </Col>
                </Row>
              )}
              <Row>
                <Col xs={12}>
                  {this.props.brandStore!.selectedClient && (
                    <div>
                      <h1>Brands</h1>
                      <label className="label-text">
                        Brand logo's will override client logo
                      </label>
                    </div>
                  )}
                </Col>
              </Row>
              <Row>
                <Col xs={12}>
                  {this.props.brandStore!.isLoadingBrands && (
                    <label>Loading...</label>
                  )}
                </Col>
              </Row>
              {!this.props.brandStore!.isLoadingBrands && (
                <Row>
                  <Col xs={12}>
                    {!this.props.brandStore!.brands.length &&
                      this.props.brandStore!.selectedClient && (
                        <label>
                          No brands for client{" "}
                          {this.props.brandStore!.selectedClient!.value}
                        </label>
                      )}
                  </Col>
                  {this.props.brandStore!.brands.map((brand: IBrand, index) => {
                    return (
                      <Row
                        key={index}
                        style={{ marginRight: 20, marginBottom: 20 }}
                      >
                        <Col xs={12}>
                          <ClientLogoUploadCard
                            onUpload={(file) => this.onUpload(file, brand.key)}
                            onDelete={() =>
                              this.onDelete(
                                this.props.brandStore!.selectedClient,
                                brand.key,
                              )
                            }
                            dropzoneText={
                              "No image is set for brand " +
                              brand.value +
                              ". Click or Drag & drop an image"
                            }
                            item={brand}
                            imageSrc={this.buildBrandImageUrl(brand)}
                          />
                        </Col>
                      </Row>
                    );
                  })}
                </Row>
              )}
            </Grid>
          )}
      </Grid>
    );
  }

  public buildBrandImageUrl(brand: IBrand): string {
    let url = "";
    if (this.props.brandStore!.selectedClient) {
      url =
        Configuration.REACT_APP_BRANDING_LOGO_ENDPOINT +
        this.props.brandStore!.selectedClient!.key +
        "/" +
        brand.key +
        "/Logo" +
        this.props.sasTokenStore!.sasToken;
    }

    return url;
  }

  private async onDelete(client?: IClient, brandId?: number): Promise<boolean> {
    if (!client) {
      return false;
    }
    try {
      await this.clientLogoService.delete(client.key, brandId);
      return true;
    } catch (error) {
      return false;
    }
  }

  private async onUpload(file: File, brandId?: number): Promise<boolean> {
    if (!this.props.brandStore!.selectedClient) {
      return false;
    }

    return this.clientLogoService
      .upload(file, this.props.brandStore!.selectedClient!.key, brandId)
      .then(() => {
        return true;
      })
      .catch(() => {
        return false;
      });
  }
}

export default BrandingLogoUpload;
