import { inject, observer } from "mobx-react";
import React from "react";
import { Route } from "react-router-dom";
import AuthStore from "../stores/AuthStore";
import GraphStore from "../stores/GraphStore";

interface IProps {
  authStore: AuthStore;
  graphStore: GraphStore;
}

@inject("authStore")
@observer
class AuthenticatedRoute extends React.Component<IProps> {
  constructor(props: IProps) {
    super(props);
  }
  public render() {
    const { authStore, ...restProps } = this.props;
    if (authStore.isLoggedIn) {
      return <Route {...restProps} />;
    } else {
      if (!authStore.accessToken) {
        authStore.getAccessToken();
      }

      return <label>Loading</label>;
    }
  }
}

export default AuthenticatedRoute;
