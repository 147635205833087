import axios from "axios";
import Configuration from "../../../Configuration";

export default class B2CApi {
  public async updateDinerByEmail(
    email: string,
    dinerId: number,
  ): Promise<string> {
    const result = await axios.post(
      Configuration.REACT_APP_B2C_UPDATE_DINER_ENDPOINT,
      {
        email,
        dinerId,
      },
      {
        headers: {
          "Accept": "application/json",
          "x-functions-key": Configuration.REACT_APP_B2C_FUNCTION_KEY,
        },
      },
    );

    return result.data;
  }

  public async getDinerIdByEmail(email: string): Promise<number> {
    const result = await axios.post<number>(
      Configuration.REACT_APP_B2C_GET_DINER_ENDPOINT,
      {
        email,
      },
      {
        headers: {
          "Accept": "application/json",
          "x-functions-key": Configuration.REACT_APP_B2C_FUNCTION_KEY,
        },
      },
    );

    return result.data;
  }
}
