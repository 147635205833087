import React from "react";
import { MdClear } from "react-icons/md";
import "./Alert.scss";

export enum AlertType {
  error = "error",
}

export const Alert = ({
  text,
  type,
  centerText = true,
  onClick,
}: {
  text: string;
  type: AlertType;
  centerText?: boolean;
  onClick?: any;
}) => (
  <div className={`alert alert-${type} ${centerText ? "centered-text" : ""}`}>
    {onClick && <MdClear className="clear-alert" onClick={onClick} />}
    <p>{text}</p>
  </div>
);
